interface IContentParams {
  contentKey: string;
  contentRoute: string[];
  where?: Record<string, any>;
  isPage?: boolean;
  only?: string[];
  findAll?: boolean;
}

const CACHE_TTL = 60 * 15; // 15 минут
const cache = new Map();

interface CacheEntry {
  data: any;
  timestamp: number;
}

export function useContentLogic<T extends Record<string, any>>(params: IContentParams) {
  interface IPageContent {
    currentLocaleData: Maybe<T>;
    defaultLocaleData: Maybe<T>;
  }

  const globalStore = useGlobalStore();
  const { isRefreshing, currentLocale, defaultLocale } = storeToRefs(globalStore);
  const { setPageMeta, getLocalesContentData } = useProjectMethods();
  const isLoading = ref(false);

  const getRequestArray = (): Promise<any>[] => {
    const currentLocaleCacheKey = `content:${currentLocale.value?.code}:${params.contentRoute.join('/')}:${JSON.stringify(params.where)}:${params.only?.join(',')}`;
    const defaultLocaleCacheKey = `content:${defaultLocale.value?.code}:${params.contentRoute.join('/')}:${JSON.stringify(params.where)}:${params.only?.join(',')}`;

    const currentLocaleCache = cache.get(currentLocaleCacheKey) as CacheEntry | undefined;
    const defaultLocaleCache = cache.get(defaultLocaleCacheKey) as CacheEntry | undefined;

    if (currentLocale.value?.isDefault) {
      if (currentLocaleCache && Date.now() - currentLocaleCache.timestamp < CACHE_TTL * 1000) {
        return [Promise.resolve(currentLocaleCache.data), Promise.reject('Current locale is default locale!')];
      }
    } else {
      if (
        currentLocaleCache &&
        defaultLocaleCache &&
        Date.now() - currentLocaleCache.timestamp < CACHE_TTL * 1000 &&
        Date.now() - defaultLocaleCache.timestamp < CACHE_TTL * 1000
      ) {
        return [Promise.resolve(currentLocaleCache.data), Promise.resolve(defaultLocaleCache.data)];
      }
    }

    let currentLocaleQuery: any = queryContent(currentLocale.value?.code as string, ...params.contentRoute);
    let defaultLocaleQuery: any = queryContent(defaultLocale.value?.code as string, ...params.contentRoute);

    if (params.only) {
      currentLocaleQuery = currentLocaleQuery.only(params.only);
      defaultLocaleQuery = defaultLocaleQuery.only(params.only);
    }

    if (params.where) {
      currentLocaleQuery = currentLocaleQuery.where(params.where);
      defaultLocaleQuery = defaultLocaleQuery.where(params.where);
    }

    const currentLocalePromise = params.findAll ? currentLocaleQuery.find() : currentLocaleQuery.findOne();
    const defaultLocalePromise = currentLocale.value?.isDefault
      ? Promise.reject('Current locale is default locale!')
      : params.findAll
        ? defaultLocaleQuery.find()
        : defaultLocaleQuery.findOne();

    currentLocalePromise.then((data: any) => {
      cache.set(currentLocaleCacheKey, {
        data,
        timestamp: Date.now(),
      });
    });

    if (!currentLocale.value?.isDefault) {
      defaultLocalePromise.then((data: any) => {
        cache.set(defaultLocaleCacheKey, {
          data,
          timestamp: Date.now(),
        });
      });
    }

    return [currentLocalePromise, defaultLocalePromise];
  };

  const getContentData = async (): Promise<IPageContent> => {
    let contentData: IPageContent = { currentLocaleData: undefined, defaultLocaleData: undefined };
    isLoading.value = true;
    const { data: nuxtDataContent } = useNuxtData(params.contentKey);

    if (nuxtDataContent.value && !isRefreshing) contentData = nuxtDataContent.value;
    else {
      const [currentLocaleContentResponse, defaultLocaleContentResponse] = await Promise.allSettled(getRequestArray());
      contentData = getLocalesContentData(currentLocaleContentResponse, defaultLocaleContentResponse);
      nuxtDataContent.value = contentData;
    }

    if (params.isPage) setPageMeta(contentData.currentLocaleData?.IPageMeta);
    isLoading.value = false;
    return contentData;
  };

  return {
    getContentData,
    isLoading,
  };
}
